<style>
.note-editor.note-frame.fullscreen { background-color:  #fff; }
</style>
<template lang="pug">
div
  div.bg-white(ref='editor' v-html='value')
</template>

<script>
window.$ = window.jQuery = require('jquery')

// require('summernote/dist/summernote-lite.min.css')
// require('summernote/dist/summernote-lite.min.js')
// require('summernote/dist/lang/summernote-ko-KR.min.js')

export default {
  name: "Editor",
  props: ['value', 'editor_options', 'onImageUpload'],
  data() {
    return {

    }
  },
  mounted() {
    $(() => {
      this.editor_options.ref = this.$refs.editor
      this.editor_options.$ref = $(this.$refs.editor)
      $(this.$refs.editor).summernote({
        lang: 'ko-KR',
        height: '300px',
        callbacks: {
          onBlur: this.onBlur,
          onImageUpload: this.onImageUpload,
        }
      })
    })
  },
  destroy() {
    $(this.$refs.editor).summernote('destroy')
  },
  methods: {
    onBlur() {
      console.log('onBlur')
      this.$emit('onBlur', {
        code: $(this.$refs.editor).summernote('code'),
      })
    },
  },
};
</script>
